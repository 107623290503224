import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import './style.css'

const Welcome = () =>{
    return(
        <div className="welcome-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="welcome-content">
                            <h2>Come to celebrate our little boy</h2>
                            <p>Our little world is going to be completed as we’re expecting our baby boy to come to this world. He is expected on September 21st 2021.</p>
                            <div className="btn"><AnchorLink href="#rsvp">RSVP</AnchorLink></div>
                            <div className="btn"><AnchorLink href="#event">Location</AnchorLink></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Welcome;