import React from 'react'

// components
import Sectiontitle from '../section-title'
import strory1 from '../../images/events/oaks.jpg'

import './style.css'

const Location = () => {
    return(


    <div id="event" className="service-area section-padding">
        <div className="container">
            <Sectiontitle section={'When & Where'}/>
            <div className="service-area-menu">
                <div className="Ceremony-wrap">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="ceromony-img">
                                <img src={strory1} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="ceromony-content">
                                <h3>The Oaks Kitchen and Bar</h3>
                                <span>Saturday, 24 July 2021, 5:00 pm - 10:00pm</span>
                                <span>2100 Cane Island Pkwy B, Katy, TX 77493</span>
                                <p>It's going to be on the second floor above the restrooms. Not inside the restaurant. Visually close to the Green House.</p>
                                <a rel="noopener noreferrer" target="_blank" href="https://www.google.com/maps/place/The+Oaks/@29.7981344,-95.8439672,253m/data=!3m2!1e3!4b1!4m5!3m4!1s0x8641256e469901af:0x9ef85f4db7871e9b!8m2!3d29.7983907!4d-95.8433655">See Location</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}
export default Location;