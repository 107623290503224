import React, { Component } from "react"

import './style.css'

class SimpleSlider extends Component {
    render() {
      return (
          <div id="home" className="item1">
              <div className="container">
                  <div className="slide-content">
                      <div className="slide-subtitle">
                          <h4> YOU'RE INVITED</h4>
                      </div>
                      <div className="slide-title">
                          <h2>We're having a <br/> baby shower!</h2>
                      </div>
                      <div className="slide-text">
                          <p>24 July 2021</p>
                      </div>
                  </div>
              </div>
          </div>

      );
    }
  }

export default SimpleSlider;