import React, { Component } from 'react';
// Notes: The conection with the database live here.
import { firebase } from '../../database';

// Components
import AddCalendar from '../addCalendar';

import './style.css'

const db = firebase.database();

class Rsvp extends Component {

    state = {
        name: '',
        email: '',
        rsvp: '',
        attending: '',
        error: {},
        submited: false,
        event: {
            title: "Lis & Leo's Babyshower",
            description: "Lis & Leo's Babyshower. <br/> <br/>The Oaks Kitchen and Bar. <br/> It's going to be on the second floor above the restrooms. Not inside the restaurant. <br/> Visually close to the Green House. <br/> <br/> <a hre='https://www.babylist.com/baby-lis-leo'>Baby Registry</a>",
            location: '2100 Cane Island Pkwy B, Katy, TX 77493',
            startTime: '2021-07-24T21:00',
            endTime: '2021-07-24T24:00'
        }
    }

    changeHandler = (e) => {
        const error = this.state.error;
        error[e.target.name] = ''

        this.setState({
            [e.target.name]: e.target.value,
            error
        })
    }

    submitDatabase(data){
        const { name, email, rsvp, attending } = data;

        // Submit to Database
        const guest = db.ref('guest-list');
        const gestInfo = guest.push();
        gestInfo.set({
            name,
            email,
            rsvp,
            attending
        });

        // Submition ends, hide submit message
        setTimeout(() =>{
            this.setState({
                submited: false
            });
        }, 6200);
    }

    subimtHandler = (e) => {
        e.preventDefault();

        const { name,
            email,
            rsvp,
            attending,
            error } = this.state;

        if (name === '') {
            error.name = "Please enter your name";
        }
        if (email === '') {
            error.email = "Please enter your email";
        }
        if (rsvp === '') {
            error.rsvp = "Select your number of rsvp";
        }
        if (attending === '') {
            error.attending = "Select your event list";
        }

        if (error) {
            this.setState({
                error
            })

            // Hide submited msj
            this.setState({
                submited: false
            });
        }

        if (error.name === '' && error.email === '' && error.email === '' && error.rsvp === '' && error.attending === '') {

            // Submited, show submit message
            this.setState({
                submited: true
            });

            // Submit database
            this.submitDatabase(this.state);

            // clear State
            this.setState({
                name: '',
                email: '',
                rsvp: '',
                attending: '',
                error: {}
            });
        }
    }

    render() {

        const { name,
            email,
            rsvp,
            attending,
            error,
            submited } = this.state;
        return (
            <div id="rsvp" className="rsvp-area go-rsvp-area section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                            <div className="rsvp-wrap">
                                <div className="col-12">
                                    <div className="section-title section-title4 text-center">
                                        <h2>Be Our Guest</h2>
                                        <p>Please confirm your attending.</p>
                                    </div>
                                </div>
                                <form onSubmit={this.subimtHandler}>
                                    <div className="contact-form form-style">
                                        <div className="row">
                                            <div className="col-12 col-sm-6">
                                                <input type="text" value={name} onChange={this.changeHandler} placeholder="Your Name*" id="fname" name="name" />
                                                <p>{error.name ? error.name : ''}</p>
                                            </div>
                                            <div className="col-12  col-sm-6">
                                                <input type="text" placeholder="Your Email*" onChange={this.changeHandler} value={email} id="email" name="email" />
                                                <p>{error.email ? error.email : ''}</p>
                                            </div>
                                            <div className="col col-sm-6 col-12">
                                                <select className="form-control" onChange={this.changeHandler} value={rsvp} name="rsvp">
                                                    <option disabled value="">Number Of rsvp*</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                </select>
                                                <p>{error.rsvp ? error.rsvp : ''}</p>
                                            </div>
                                            <div className="col col-sm-6 col-12">
                                                <select className="form-control" onChange={this.changeHandler} value={attending} name="attending">
                                                    <option disabled value="">I Am Attending*</option>
                                                    <option value="yes">Yes, I'll be there.</option>
                                                    <option value="maybe">Maybe, I'm not sure.</option>
                                                </select>
                                                <p>{error.attending ? error.attending : ''}</p>
                                            </div>
                                        </div>
                                        <div className="col-12 text-center">
                                            <button id="submit" type="submit" className="submit">Send Response</button>
                                            {submited &&
                                                <p
                                                    className={`form-confirmation ${submited ? "fadeOut" : ""}` }
                                                >
                                                Thanks! your response have been send.</p>
                                            }
                                        </div>

                                        <div className="col-12 text-center">
                                            <AddCalendar infoEvent={this.state.event}/>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Rsvp;