import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import { firebase } from '../../database'

// Component
import Sectiontitle from '../section-title'

import './style.css'

class GuestList extends Component{

  state = {
    guestList: [],
  }

  componentDidMount(){
    const dbRef = firebase.database().ref();
    dbRef.child("guest-list").on("value", snapshot => {
      let guestList = [];
      snapshot.forEach(snap =>{
        guestList.push(snap.val());
      });
      this.setState({ guestList });
    })
  }

  render(){

    const { guestList } = this.state;
    const rsvp = guestList
    .map(guest => guest.rsvp*1 )
    .reduce((a, b) => a + b, 0)

    return(
      <div className="guest-list">
      <div className="container">
          <div className="navBack">
            <p><Link to='/'>Back Home</Link></p>
          </div>
          <div className="guest-area">
            <Sectiontitle section={'Guest List'}/>
            <div className="guest-content">
              <div className="guest-total">
                <p>Total RSVP: </p><span>{rsvp}</span>
              </div>
              <ul>
                <li className="guest-list-title">
                  <span>Guest Name</span>
                  <span>Attending</span>
                  <span>RSVP</span>
                </li>
                {guestList.map((guest => (
                  <li key={guest.name}>
                    <span>{guest.name}</span>
                    <span>{guest.attending}</span>
                    <span>{guest.rsvp}</span>
                  </li>
                )))}

              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default GuestList;