import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './style.css'

class Gift extends Component {
    render() {
      return (
          <div className="gift-area" id="gift">
              <div className="container">
                    <div className="col-12">
                      <div className="section-title text-center">
                          <h2>Gift Registration</h2>
                          <p>Oh Boy! A little gentleman is on his way to make you smile.</p>
                          <div className="btn"><a rel="noopener noreferrer" target="_blank" href="https://www.babylist.com/baby-lis-leo">Baby Registry</a></div>
                      </div>
                  </div>
              </div>
          </div>
      );
    }
  }

export default Gift;