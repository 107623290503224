import React from 'react';
import AddToCalendar from 'react-add-to-calendar';

import './style.css';

const AddCalendar = ({infoEvent}) => {
  let items = [
    { apple: 'iCal' },
    { google: 'Google' }
 ];

 let icon = { 'calendar-plus-o': 'left' };

  return(
    <AddToCalendar
      event={infoEvent}
      listItems={items}
      buttonTemplate={icon}
      buttonLabel="Save Event to my calendar"
    />
  );

}

export default AddCalendar;