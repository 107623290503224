import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

// Notes: the conection is inside the components > rsvs > index.js.
// The database has a owner code. If delete the access will be revoke.

const config = {
  apiKey: "AIzaSyCYBkaXz1UHGFdN4OxoHhQYByHLt7NQklc",
  authDomain: "babyshower-lis-leo.firebaseapp.com",
  databaseURL: "https://babyshower-lis-leo-default-rtdb.firebaseio.com/"
};

firebase.initializeApp(config);

export { firebase };

