import React, {useEffect, useState} from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'

import './style.css'

const Scrollbar = () => {

    const [ scrollValue, setScrollValue] = useState(0);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            setScrollValue(window.pageYOffset);
        })
    }, [])

    return(
        <div className="col-lg-12">
            <div className="header-menu">
                <ul className="smothscroll">
                    {scrollValue > 70 && <li><AnchorLink href='#home'><i className="fa fa-arrow-up"></i></AnchorLink></li>}
                </ul>
            </div>
        </div>
    )
}

export default Scrollbar;
