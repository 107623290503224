import React from  'react'

// components
import GuestList from '../../components/guestList'

const GuestPage = () => {
    return(
       <div>
           <GuestList />
       </div>
    )
}

export default GuestPage;